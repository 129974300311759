export const resour = {
    "card_1":{
      "title":"Card Title",
      "description": "Fundamentals of version control.",

    },
    "card_2":{
      "title":"Card Title",
      "description": "Fundamentals of version control.",
    },
    "card_3":{
      "title":"Card Title",
      "description": "Fundamentals of version control.",
    },
    "card_4":{
      "title":"Card Title",
      "description": "Fundamentals of version control.",
    },
}

export const eventss = {
  "card_1":{
    "title":"Card Title",
    "time" : "Nov 29, 2023 | 7PM",
    "description": "Learn the fundamentals of version control with Git and collaborative coding using GitHub in this introductory session."

  },
  "card_2":{
    "title":"Card Title",
    "time" : "Nov 29, 2023 | 7PM",
    "description": "Learn the fundamentals of version control with Git and collaborative coding using GitHub in this introductory session."

  },
  "card_3":{
    "title":"Card Title",
    "time" : "Nov 29, 2023 | 7PM",
    "description": "Learn the fundamentals of version control with Git and collaborative coding using GitHub in this introductory session."

  },
  "card_4":{
    "title":"Card Title",
    "time" : "Nov 29, 2023 | 7PM",
    "description": "Learn the fundamentals of version control with Git and collaborative coding using GitHub in this introductory session."
  },
  "card_5":{
    "title":"Card Title",
    "time" : "Nov 29, 2023 | 7PM",
    "description": "Learn the fundamentals of version control with Git and collaborative coding using GitHub in this introductory session."
  },
}
