// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Events from './pages/Events';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Resources from './pages/Resources';
import AdminSignIn from './components/adminpanel/AdminSignIn';
import AdminDashboard from './components/adminpanel/AdminDashboard';
import ContactUsResults from './components/adminpanel/ContactUsResults';
import EventManager from './components/adminpanel/EventManager';
import ResourceManager from './components/adminpanel/ResourceManager';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { AuthProvider } from './components/adminpanel/context/AuthContext';
import ProtectedRoute from './components/adminpanel/ProtectedRoute';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/resources",
    element: (
      <>
        <Resources />
      </>
    ),
  },
  {
    path: "/events",
    element: (
      <>
        <Events />
      </>
    ),
  },
  {
    path: "/contactus",
    element: (
      <>
        <ContactUs />
      </>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <>
        <AboutUs />
      </>
    ),
  },
  // Admin routes
  {
    path: "/admin",
    element: <AdminSignIn />,
  },
  {
    path: "/admin/dashboard",
    element: <ProtectedRoute element={<AdminDashboard />} />,
  },
  {
    path: "/admin/contact-us",
    element: <ProtectedRoute element={<ContactUsResults />} />,
  },
  {
    path: "/admin/events",
    element: <ProtectedRoute element={<EventManager />} />,
  },
  {
    path: "/admin/resources",
    element: <ProtectedRoute element={<ResourceManager />} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
