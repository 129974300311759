import React from 'react';
import { Link } from 'react-router-dom';
import './AdminNavBar.css'; // Import the CSS file for styling

function AdminNavBar() {
  return (
    <nav className="admin-nav-bar">
      <ul className="admin-nav-list">
        <li className="admin-nav-item">
          <Link to="/admin/dashboard" className="admin-nav-link">Dashboard</Link>
        </li>
        <li className="admin-nav-item">
          <Link to="/admin/contact-us" className="admin-nav-link">Contact Us Submissions</Link>
        </li>
        <li className="admin-nav-item">
          <Link to="/admin/events" className="admin-nav-link">Event Manager</Link>
        </li>
        <li className="admin-nav-item">
          <Link to="/admin/resources" className="admin-nav-link">Resource Manager</Link>
        </li>
      </ul>
    </nav>
  );
}

export default AdminNavBar;
