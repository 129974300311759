import React from "react";
import './landing.css';
import landingImage from '../assets/landing.svg'; // Import your image

function Landing() {
    return (
        <div className="landspace">
            <div className="landhead">Experience a learning journey like no other!</div>
            <div className="landp">We are a pioneering edtech startup with a vision to revolutionize the way students access and utilize knowledge.</div>
            <img src={landingImage} alt="Landing" className="landimg" />
            <div className="landbut row">
                <div className="col-md-6">
                    <button className="getbut btn btn-lg">Get Started</button>
                </div>
                <div className="col-md-6">
                    <button className="knowbut btn btn-lg">Know More</button>
                </div>
            </div>
        </div>
    );
}

export default Landing;
