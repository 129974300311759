import React, { useState, useEffect } from 'react';
import searchIcon from '../assets/Search.svg'; // Check and adjust the path
import './search.css';

const SearchBar = ({ setSearchQuery }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 950); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial view
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div
      style={{
        width: isMobileView ? '95%' : '52%',
        marginTop: '210px',
        height: isMobileView ? '25%' : '40%',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        borderRadius: '4px',
        background: '#ffffff',
        border: '1px solid #000',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <div style={{ marginRight: '22px', marginLeft: isMobileView ? '10px' : '22px' }}>
        <img src={searchIcon} alt="Search" style={{ width: isMobileView ? '20px' : 'auto', height: isMobileView ? '20px' : 'auto' }} />
      </div>
      <div style={{ flexGrow: 1 }}>
        <input
          type="text"
          placeholder="Search..."
          onChange={handleSearchChange}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '0',
            color: '#999',
            border: 'none',
            outline: 'none',
          }}
        />
      </div>
      {/* Add filter UI as needed */}
    </div>
  );
};

export default SearchBar;
