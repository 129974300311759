import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { eventss } from '../../contents/resources';
import cimg from '../../assets/img.webp';
import './cards.css'

function Cards() {
  const cardKeys = Object.keys(eventss);

  const cardComponents = cardKeys.map((cardKey) => {
    const { title, time, description, tags } = eventss[cardKey];
    return (
      <div key={cardKey} className="d-flex justify-content-center col-lg-3 col-md-6 col-sm-12 mb-4">
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={cimg} />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{time}</Card.Text>
            <Card.Text>{description}</Card.Text>
            <div style={{ paddingBottom: '20px' }}>
            </div>
            <Button
  variant="primary"
  className="registerButton" // Add this class for animations
  style={{
    backgroundColor: 'black',
    borderRadius: '8px',
    borderColor: 'black',
    width: '100%'
  }}
>
  Register
</Button>
          </Card.Body>
        </Card>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="row justify-content-center justify-content-md-start">{cardComponents}</div>
    </div>
  );
}

export default Cards;
