import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import "./EventManager.css";
import AdminNavBar from "./AdminNavBar"; // Import the AdminNavBar component

function EventManager() {
  const [eventName, setEventName] = useState("");
  const [status, setStatus] = useState("ongoing");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));
      setEvents(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchEvents();
  }, []);

  const handleAddEvent = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "events"), { name: eventName, status });
      setEventName("");
      setStatus("ongoing");
      // Refresh the event list
      const querySnapshot = await getDocs(collection(db, "events"));
      setEvents(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    } catch (error) {
      console.error("Error adding event: ", error);
    }
  };

  const handleUpdateStatus = async (eventId, newStatus) => {
    const eventRef = doc(db, "events", eventId);
    await updateDoc(eventRef, { status: newStatus });
    // Refresh the event list
    const querySnapshot = await getDocs(collection(db, "events"));
    setEvents(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  return (
    <div className="event-manager-container">
      <AdminNavBar /> {/* Include the AdminNavBar component */}
      <h2>Event Manager</h2>
      <form className="event-manager-form" onSubmit={handleAddEvent}>
        <input
          type="text"
          placeholder="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          required
        />
        <select value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="ongoing">Ongoing</option>
          <option value="completed">Completed</option>
        </select>
        <button type="submit">Add Event</button>
      </form>
      <div className="events-list">
        <h3>Current Events</h3>
        <ul>
          {events.map((event) => (
            <li key={event.id} className="event-item">
              <span className="event-name">{event.name}</span>
              <span className={`event-status ${event.status}`}>
                {event.status}
              </span>
              <button
                onClick={() =>
                  handleUpdateStatus(
                    event.id,
                    event.status === "ongoing" ? "completed" : "ongoing"
                  )
                }
              >
                Mark as {event.status === "ongoing" ? "Completed" : "Ongoing"}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EventManager;
