import React from "react";
import './aboutus.css';
import Navbare from "../components/Navbar/navbar";
import Weareeldi from "../components/aboutus/WeAreEldi";
import Footer from "../components/Footer/Footer";
import Ellipsecom from "../components/aboutus/ellipsecom";

function AboutUs() {
  return (
    <>
      <Navbare/>
      <Weareeldi/>
      <Ellipsecom/>
 
  
       <Footer/>
    </>
  );
}

export default AboutUs;
