import React from "react";
import Navbare from '../components/Navbar/navbar.jsx';
import Cards from '../components/cards/Cards.jsx';
import PCards from '../components/cards/PCards.jsx';
import Footer from '../components/Footer/Footer.jsx';
import EventSection from '../components/evsection.jsx';
import './events.css'

function Events() {
    return(
        <div>
            <Navbare />
            <EventSection />
            <div className="evspace">
                <h1>Current Events</h1>
            </div>
            <Cards />
            <div className="evspace">
                <h1>Past Events</h1>
            </div>
            <PCards />
            <Footer />
        </div>
    );
}

export default Events;
