import React from "react";
import ellipse1 from '../../assets/aboutassets/Subtract.svg';
import ellipse2 from '../../assets/aboutassets/Subtract2.svg';
import ellipse3 from '../../assets/aboutassets/Ellipse.svg';
import learning from '../../assets/aboutassets/Learning.svg';
import community from '../../assets/aboutassets/Community.svg';
import tutorial from '../../assets/aboutassets/Tutorials.svg';
import './ellipsecm.css';

function Ellipsecom() {
  return (
    <div className="container-fluid ellipses">
        <div className="ellipse-content">
            <div className="content">
                <img src={learning} alt="icon1" className="icon img-fluid" />
                <span className="icontext text1" style={{color:'#FFF'}}>Diverse Learning Resources</span>
            </div>
        </div>
        <div className="ellipse-content2">
            <div className="content">
                <img src={community} alt="icon1" className="icon img-fluid" />
                <span className="icontext text2" style={{color:'#000064'}}>Community-Centric Approach</span>
            </div>
        </div>
        <div className="ellipse-content">
            <div className="content">
                <img src={tutorial} alt="icon1" className="icon img-fluid" />
                <span className="icontext text3" style={{color:'#FFF'}}>Curated Courses and Workshops</span>
            </div>
        </div>
        
    </div>
  );
}

export default Ellipsecom;
