import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, getDocs } from "firebase/firestore";
import "./ResourceManager.css";
import AdminNavBar from "./AdminNavBar";

function ResourceManager() {
  const [resourceName, setResourceName] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "resources"));
        const resourceList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourceList);
      } catch (error) {
        console.error("Error fetching resources: ", error);
      }
    };

    fetchResources();
  }, []);

  const handleAddResource = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "resources"), { 
        name: resourceName, 
        url, 
        description 
      });
      setSuccessMessage("Resource added successfully!");
      setResourceName("");
      setUrl("");
      setDescription("");
      setErrorMessage("");

      // Refresh the resource list
      const querySnapshot = await getDocs(collection(db, "resources"));
      const resourceList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResources(resourceList);
    } catch (error) {
      console.error("Error adding resource: ", error);
      setErrorMessage("Failed to add resource. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="resource-manager-container">
      <AdminNavBar /> {/* Include the AdminNavBar component */}
      <h2 className="resource-manager-header">Resource Manager</h2>
      <form className="resource-manager-form" onSubmit={handleAddResource}>
        <input
          type="text"
          placeholder="Resource Name"
          value={resourceName}
          onChange={(e) => setResourceName(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="url"
          placeholder="Resource URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
          className="input-field"
        />
        <textarea
          placeholder="Resource Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="input-field"
        />
        <button type="submit" className="submit-button">
          Add Resource
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="resources-list">
        <h3>Added Resources</h3>
        <ul>
          {resources.map((resource) => (
            <li key={resource.id}>
              <a href={resource.url} target="_blank" rel="noopener noreferrer">
                {resource.name}
              </a>
              <p>{resource.description}</p> {/* Display the description */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ResourceManager;
