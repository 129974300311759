// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA458X6GHa-K_W_1vgaxX4w2AC7j0ghZ7s",
    authDomain: "eldii-d2ba3.firebaseapp.com",
    projectId: "eldii-d2ba3",
    storageBucket: "eldii-d2ba3.appspot.com",
    messagingSenderId: "136723818829",
    appId: "1:136723818829:web:446f1769aeabde919e2f04"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };