import React from "react";
import aboutus from '../../assets/aboutus1.svg';
import './weareeldi.css';

function Weareeldi() {
  return (
    <div className="container">
      <div className="row align-items-center justify-content-between" style={{marginTop:'50px'}}>
        <div className="col-lg-6">
          <div className="wemain">
            <div className="weare">
              We are <span className="weareldii text-white p-1">ELDII.</span>
            </div>
            <p className="wearep">
              Step into Eldii, redefining education for a brighter future. We’re
              committed to affordable, high-quality learning that empowers
              students with real-world skills. Join us in reshaping how knowledge
              transforms lives.
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <img src={aboutus} alt="Aboutus" className="aboutusimg img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default Weareeldi;
