import React from "react";
import "./ContactUs.css";
import Navbare from "../components/Navbar/navbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import { db } from "../firebaseConfig.js"; // Import the Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Import Firestore methods

function ContactUs() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    try {
      // Add a new document with a generated ID
      const docRef = await addDoc(collection(db, "contact_form"), {
        name,
        email,
        message,
      });
      console.log("Document written with ID: ", docRef.id);

      // Reset the form after successful submission
      event.target.reset();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <Navbare />
      <div className="containercon">
        <div className="first-grid">
          <h1 className="contact-text">Contact Us</h1>
          <h1 className="contact-text1">Contact Us</h1>
          <h1 className="contact-text2">Contact Us</h1>
          <h1 className="contact-text3">Contact Us</h1>
          <p className="text-first-grid">
            For any support, Please mail to info@eldii.org Contact us on
            XXXXXXXXXX. Our Support team will get back to you within 24 hours.
          </p>
        </div>
        <div className="mesg">
          <form onSubmit={handleSubmit}>
            <div className="right">
              <div className="input-box">
                <input type="text" name="name" className="input" autoComplete="name" required />
                <label htmlFor="">Name</label>{" "}
              </div>
              <div className="input-box">
                <input type="email" name="email" className="input" autoComplete="email" required />
                <label htmlFor="">Email</label>{" "}
              </div>
              <div className="input-box">
                <textarea
                  name="message"
                  className="input"
                  cols="30"
                  rows="10"
                  required
                ></textarea>
                <label htmlFor="">Message</label>{" "}
              </div>
              <button type="submit" className="btncon">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
