import React from "react";
import { Link } from "react-router-dom";
import "./AdminDashboard.css";

function AdminDashboard() {
  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Admin Dashboard</h1>
      </div>
      <nav className="dashboard-nav">
        <div className="dashboard-card">
          <Link to="/admin/contact-us">Contact Us Submissions</Link>
          <p>View and manage all user submissions from the contact us form.</p>
        </div>
        <div className="dashboard-card">
          <Link to="/admin/events">Event Manager</Link>
          <p>Create, update, and manage events easily.</p>
        </div>
        <div className="dashboard-card">
          <Link to="/admin/resources">Resource Manager</Link>
          <p>Add and manage resources for your users.</p>
        </div>
      </nav>
    </div>
  );
}

export default AdminDashboard;
