import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import "./ContactUsResults.css";
import AdminNavBar from "./AdminNavBar"; // Import the AdminNavBar component

function ContactUsResults() {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      const querySnapshot = await getDocs(collection(db, "contact_form"));
      setSubmissions(querySnapshot.docs.map((doc) => doc.data()));
    };
    fetchSubmissions();
  }, []);

  return (
    <div className="contact-us-container">
      <AdminNavBar /> {/* Include the AdminNavBar component */}
      <h2 className="contact-us-header">Contact Us Submissions</h2>
      <ul className="contact-us-list">
        {submissions.map((submission, index) => (
          <li key={index} className="contact-us-item">
            <p className="contact-us-name">
              <strong>Name:</strong> {submission.name}
            </p>
            <p className="contact-us-email">
              <strong>Email:</strong> {submission.email}
            </p>
            <p className="contact-us-message">
              <strong>Message:</strong> {submission.message}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ContactUsResults;
