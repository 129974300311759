import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css"; // Import your CSS file
import { Link } from "react-router-dom";

const Navbare = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 950); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial view
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleNavLinks = (event) => {
    event.preventDefault(); // Prevent immediate navigation
    setShowNavLinks(!showNavLinks);
  };

  return (
    <nav className="navbar">
      <div className={`prof-cont ${showNavLinks ? "active" : ""}`}>
      <div className={`sub-prof ${showNavLinks ? "active" : ""}`}>
        <div className="brand-title"><a href="/" className="brand-t">eldii.</a></div>
        <div className="tog">
          <a href="/" className="toggle-button" onClick={toggleNavLinks}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </a>
        </div>
        </div>
        <div className={`navbar-links ${showNavLinks ? "active" : ""}`}>
          <ul style={{ gap: isMobileView ? "50px" : "60px" }}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
            <li>
              <Link to="/events">Events</Link>
            </li>
            <li>
              <Link to="/aboutus">About Us</Link>
            </li>
            <li>
              <Link to="/contactus" className="btn btncolor">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbare;
