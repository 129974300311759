import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/logo.jpeg';
import twitt from '../../assets/twitter.svg';
import linke from '../../assets/linkedin.svg';
import inst from '../../assets/instagram.svg';
import './footer.css';

function Footer() {
  return (
    <Fragment>
      <footer className='footer'>
        <div className="footer-content1">
          <div className='conta'>
            <div className='img-logo'>
              <img src={logo} alt="" className='logo' />
            </div>
          </div>
          <div className='conta1'>
            {/* Navigation Links */}
            <div className='sub'>
              <div className='head'>ELDII</div>
              <div className='para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
            </div>
            <div className='sub'>
              <div className='head'>NAVIGATION</div>
              {/* Links to Pages */}
              <div><Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link></div>
              <div><Link to="/resources" style={{ textDecoration: 'none', color: 'white' }}>Resources</Link></div>
              <div><Link to="/events" style={{ textDecoration: 'none', color: 'white' }}>Events</Link></div>
              <div><Link to="/aboutus" style={{ textDecoration: 'none', color: 'white' }}>About Us</Link></div>
            </div>
            {/* Contact Information */}
            <div className='sub'>
              <div className='head'>CONTACT</div>
              <div>India</div>
              <div>officialeldii@gmail.com</div>
            </div>
            {/* Social Media Links */}
            <div className='sub'>
              <div className='head'>FOLLOW US</div>
              <div className='icons-cont'>
                <div>
                  <a className="icons" href="#!" role="button">
                    <img src={twitt} alt="twitter link" />
                  </a>
                </div>
                <div>
                  <a className="icons" href="#!" role="button">
                    <img src={linke} alt="twitter link" />
                  </a>
                </div>
                <div>
                  <a className="icons" href="#!" role="button">
                    <img src={inst} alt="twitter link" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content2">
          © 2023 Copyright:
          <a href="https://eldii.org/"> ELDII.org</a>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
