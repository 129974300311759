import React from "react";
import './evesection.css';

function EventSection() {
    return(
        <div className="evsec">
            <h1 className="evhead" ><strong>Events</strong></h1>
        </div>
    );
}

export default EventSection;
