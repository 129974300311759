import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './ecards.css';

function ECards({ searchQuery, resources }) {
  const cardKeys = Object.keys(resources);

  const filteredCards = cardKeys.filter((cardKey) => {
    const resource = resources[cardKey];
    const title = resource.name || ''; // Use `name` as title
    const description = resource.description || '';
    const query = searchQuery.toLowerCase();

    return (
      title.toLowerCase().includes(query) ||
      description.toLowerCase().includes(query)
    );
  });

  const cardComponents = filteredCards.map((cardKey) => {
    const resource = resources[cardKey];
    const title = resource.name || ''; // Use `name` as title
    const description = resource.description || '';
    const url = resource.url || '#'; // Default to '#' if no URL is provided

    return (
      <div key={cardKey} className="col-md-4 mb-4">
        <Card>
          <Card.Body>
            <div style={{ width: '5px', height: '20px', backgroundColor: 'violet', float: 'left', marginRight: '10px' }}></div>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Button
                variant="primary"
                className="registerButton1"
                style={{
                  backgroundColor: 'violet',
                  color: 'black',
                  borderRadius: '0px',
                  borderColor: 'black',
                }}
              >
                LEARN MORE
              </Button>
            </a>
          </Card.Body>
        </Card>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="row">{cardComponents}</div>
    </div>
  );
}

export default ECards;
