import React, { useState, useEffect } from "react";
import Navbare from '../components/Navbar/navbar.jsx';
import ECards from "../components/cards/ECards.jsx";
import Footer from '../components/Footer/Footer.jsx';
import SearchBar from "../components/Search.jsx";
import { db } from '../firebaseConfig.js'; // Ensure the correct path to your firebaseConfig file
import { collection, getDocs } from 'firebase/firestore';
import './resources.css';

const Resources = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [resources, setResources] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'resources'));
        const resourcesData = {};
        querySnapshot.forEach((doc) => {
          resourcesData[doc.id] = doc.data();
        });
        setResources(resourcesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, []);

  return (
    <>
      <Navbare />
      <div className="page-container">
        <SearchBar setSearchQuery={setSearchQuery} />
      </div>
      <div className="space"></div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ECards searchQuery={searchQuery} resources={resources} />
      )}
      <div className="space"></div>
      <Footer />
    </>
  );
}

export default Resources;
